<template>
  <div id="app">
    <router-view v-if="['/activation','/loginCheck'].includes($route.path)" />
    <div v-else ref="content" class="container">
        <header>
            <div class="header">
                <div class="logo-wrap">
                    <div class="logo" :style="{ 'backgroundImage': 'url(' + logoUrl + ')' }" @click="toRoute('/')"></div>
                </div>
                <div class="header-right">
                    <el-tabs v-model="activeTag" @tab-click="handleClick">
                      <el-tab-pane label="主页" name="/"></el-tab-pane>
                      <!-- <el-tab-pane label="公司介绍" name="/introduce"></el-tab-pane> -->
                      <el-tab-pane name="/mainbusines">
                         <!-- 插入内容 -->
                        <span slot="label">
                        <!-- handleAllExamList方法为点击下拉选中内容后刷新页面执行的方法 -->
                        <el-dropdown placement="bottom" @command="handleAllExamList">
                            <span class="tabs_title">主营业务</span>
                            <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-for="menu in dropdownMenu"
                                :key="menu.name"
                                :command="menu.command"
                            >
                                {{ menu.label }}
                            </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        </span>
                      </el-tab-pane>
                      <el-tab-pane name="/aiCase">
                         <!-- 插入内容 -->
                        <span slot="label">
                        <el-dropdown placement="bottom" @command="handleGo">
                            <span class="tabs_title">行业案例</span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="aiCase">AI教育案例</el-dropdown-item>
                                <el-dropdown-item command="industryCases">AI工业案例</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        </span>
                      </el-tab-pane>
                      <el-tab-pane label="新闻资讯" name="/news"></el-tab-pane>
                      <el-tab-pane label="公司简介" name="/extrotec"></el-tab-pane>
                      <el-tab-pane label="中心简介" name="/aicenter"></el-tab-pane>
                      <el-tab-pane label="联系我们" name="/new-aboutus"></el-tab-pane>
                      <el-tab-pane v-if="0" name="/aboutus">
                       <span slot="label">
                        <!-- <el-dropdown placement="bottom" @command="handleGo">
                            <span class="tabs_title">关于我们</span>
                        </el-dropdown> -->
                        <el-dropdown placement="bottom" @command="handlePoint">
                            <span class="tabs_title">关于我们</span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="/aboutus,#m1">新闻资讯</el-dropdown-item>
                                <el-dropdown-item command="/aboutus,#m2">智算中心</el-dropdown-item>
                                <el-dropdown-item command="/aboutus,#m3">中科逆熵</el-dropdown-item>
                                <el-dropdown-item command="/aboutus,#m4">联系我们</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        </span>
                      </el-tab-pane>
                    </el-tabs>
                    <!-- <div v-if="username" class="username">
                        <el-dropdown @command="clickDropDown">
                            <span class="el-dropdown-link user-drop">
                                {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="logout">退出</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div v-else class="header-right-button">
                        <el-button class="primaryBtn" @click="toLogin" >登录 / 注册</el-button>
                    </div> -->
                    <div>
                        <el-button class="primaryBtn" style="font-size:0.16rem;margin: 0 0.1rem;background-color: #196ECE;color: #fff;" @click="applyVisible=!applyVisible">试用申请</el-button>
                    </div>
                </div>
            </div>
        </header>
        <main>
            <solution-select class="solutionWrap" v-if="showSolution" @close='close' @getData="getData"></solution-select>
            <router-view ref="children"/>
        </main>
        <div class="service-wrap" v-if="this.$route.name != 'consult'" :style="{ 'backgroundImage' : `url(${require('@/assets/images/footterLogo.png')})`}">
            <div class="service">
               <div class="footer_container">
                    <div class="footer-flex">
                        <div class="footter-logo background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/new_home/new-footterLogo.png')})`,'margin-right':'1rem','background-size': 'contain'}">
                        </div>
                        <div class="footter-middle" style="margin-top: 0.14rem;">
                            <div class="item-wrap" v-for="item in footerList" :key='item.index'>
                                <div class="item-wrap-icon background"  :style="{ 'backgroundImage' : `url(${ item.img })`}"></div>
                                <div class="footer-flex" style="flex-direction: column;justify-content: space-around;">
                                    <span class="item-wrap-text" style="color:#adbcbf">{{ item.label }}</span>
                                    <span class="item-wrap-text">{{ item.info }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="background" style="margin-top:0.1rem">
                        <div style="font-size: 0.12rem;color: #FFFFFF">中科逆熵公众号</div>
                        <img src="@/assets/images/wxlogo.png" alt="" style="width:1rem;height:1rem;margin-top:0.1rem">
                    </div>
                </div>
            </div>
        </div>
        <!-- <footer>
            <div class="footer" :style="{ 'backgroundImage' : `url(${require('@/assets/images/footnew.png')})`}">
                <div class="footer_container">
                    <div class="footer-flex">
                        <div class="footter-logo background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/new_home/logoW.png')})`,'margin-right':'1rem'}">
                        </div>
                        <div class="footter-middle" style="margin-top: 0.14rem;">
                            <div class="item-wrap" v-for="item in footerList" :key='item.index'>
                                <div class="item-wrap-icon background"  :style="{ 'backgroundImage' : `url(${ item.img })`}"></div>
                                <div class="footer-flex" style="flex-direction: column;justify-content: space-around;">
                                    <span class="item-wrap-text" style="color:#adbcbf">{{ item.label }}</span>
                                    <span class="item-wrap-text">{{ item.info }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="footter-logo background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/wxlogo.jpg')})`, 'width':'1rem', 'height': '1rem','marginTop':'0.1rem'}">
                    </div>
                </div>
            </div>
        </footer> -->
        <div class="num">
            <span>Copyright© 2021-2023南京中科逆熵科技有限公司版权所有 </span><div :style="{ 'backgroundImage' : `url(${require('@/assets/images/police.png')})`}" style="background-repeat: no-repeat;background-size: cover;background-position: center;width:0.16rem;height:0.16rem;display:inline-block;position:relative;top:0.04rem"></div><span style="color:#efefef">苏公网安备32011502012638号 </span> <a style="color:#efefef;" href="https://beian.miit.gov.cn/#/Integrated/index">苏ICP备2021008172号-1</a>
        </div>
        <!-- <div class="customer">
            <span class="icon"></span>
            <span class="consultBtn"  @click="dialogVisible = !dialogVisible">
                <span>联系我们</span>
            </span>
            <img @click="toTop" src="./assets/images/toTop.png" alt="">
        </div> -->
        <div class="dialog" v-if="dialogVisible">
            <p class="title" :style="{ 'backgroundImage' : `url(${require('@/assets/images/concatusbj.png')})`,'width':'100%','height':'0.85rem','background-size':'cover'}">联系我们
               <i class="el-icon-close" @click="dialogVisible = false" style="float:right;cursor: pointer;margin: 0.06rem;"></i>
            </p>
            <div class="flex-center-column dialog-main">
                <div class="info"><div class="left">地址：</div><span class="right">江苏省南京市麒麟科技创新园麒麟人工智能产业园 5号楼609室</span></div>
                <div class="info"><div class="left">电话：</div><span class="right">025-52125063</span></div>
                <div class="info"><div class="left">邮箱：</div><span class="right">marketing@extrotec.com</span></div>
            </div>
        </div>
        <div class="screenBlack" v-if="dialogVisible"></div>
        <div class="dialog" v-if="applyVisible">
            <p class="title" :style="{ 'backgroundImage' : `url(${require('@/assets/images/tryapply.png')})`,'width':'100%','height':'0.85rem','background-size':'cover'}">建设智能计算生态    赋能产业发展创新
               <i class="el-icon-close" @click="applyVisible = false" style="float:right;cursor: pointer;margin: 0.06rem;"></i>
            </p>
            <div class="flex-center-column dialog-main2">
                <el-form :model="form" :rules="rules" ref="form" label-width="1rem" class="demo-ruleForm">
                    <el-form-item label="需求描述：" prop="reqDesc">
                        <el-input type="textarea" v-model="form.reqDesc" resize="none"></el-input>
                    </el-form-item>
                    <div class="formlist">
                        <el-form-item label="姓名：" prop="userName" style="width:49%">
                            <el-input v-model="form.userName"></el-input>
                        </el-form-item >
                        <el-form-item label="联系电话：" prop="phone" style="width:49%">
                            <el-input v-model="form.phone"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item >
                        <div style="width:100%">
                        <el-button type="primary" @click="submitApply('form')">立即提交</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <!-- <div class="screenBlack" v-if="applyVisible"></div> -->
    </div>
  </div>
</template>
<script>
/* eslint-disable */
(function(){
    var bp = document.createElement('script');
    var curProtocol = window.location.protocol.split(':')[0];
    if (curProtocol === 'https'){
        bp.src = 'https://zz.bdstatic.com/linksubmit/push.js';
    }
    else{
        bp.src = 'http://push.zhanzhang.baidu.com/push.js';
    }
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(bp, s);
})();
</script>
<script>
/* eslint-disable */
import solutionSelect from "@/views/solutionSelect";
import userService from "@/api/api";
export default{
  components:{solutionSelect},
  watch:{
      '$route.path':{
          handler(val,O){
              this.activeTag = val
              if(val == '/peripherals') this.activeTag = '/mainbusines'
              if(val == '/industryCases') this.activeTag = '/aiCase'
              console.log(val)
              this.username = localStorage.getItem('username');
          }
      }
  },
  data(){
    return{
        reqDesc:'',
        name:'',
        phone:'',
        applyVisible:false,
        dropdownMenu:[
            {
            label: '算力服务',
            command: '/mainbusines'
            },
            {
            label: 'Al周边产品',
            command: '/peripherals'
            },
        ],
        showSolution:false,
        activeTag:'',
        username: '',
        dialogVisible: false,
        logoUrl:require('@/assets/images/new_home/logo.png'),
        footerList:[
            { info:'025-52125063', img:require('@/assets/images/new_home/footIcon1.png')},
            { info:'江苏省南京市麒麟科技创新园麒麟人工智能产业园5号楼609室', img:require('@/assets/images/new_home/footIcon2.png')},
            { info:'marketing@extrotec.com', img:require('@/assets/images/new_home/footIcon3.png')},
        ],
        form: {
          userName: '',
          phone: '',
          reqDesc: ''
        },
        rules: {
          userName: [
            { required: true, message: '请输入名字', trigger: 'blur' },
          ],
          phone:[
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确电话号码',},
          ],
          reqDesc: [
            { required: true, message: '请填写需求描述', trigger: 'blur' }
          ]
        }
      };
  },
  created(){
      let u = navigator.userAgent;
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
      let a = document.getElementById('app')
      if (isAndroid) {
          a.style = "min-width: 8rem;height: 100%;"
      } else {
          a.style = ""
      }
      this.username = localStorage.getItem('username');
  },
    methods: {
        handleAllExamList(val){
            if(val!= this.$route.path){
                this.$router.push(val)
                this.showSolution = false
            } else if (this.$route.path == '/currency' || this.$route.path == '/currencyChild') {
                this.showSolution = true
            }
        },
        handleGo(val){
            if(val!= this.$route.path){
                this.$router.push(val)
            }
        },
        handlePoint(val){
            let value=val.split(',')
            if(window.location.pathname==value[0]){
                this.$refs['children'].goAnchorpoint(value[1][value[1].length-1],value[1])
            }else{
                localStorage.setItem('toId',value[1]);
                this.$router.push({path:value[0]})
            }
        },
        close(){
            this.showSolution = false
        },
        
        handleClick(val) {
            if(val.name=='/viewHome'){
               this.activeTag=this.$router.path
               window.open('https://ev.extrotec.com/')
               return
            }
            if(val.name != this.$route.path){
                this.$router.push( val.name)
                this.showSolution = false
            } else if (this.$route.path == '/currency' || this.$route.path == '/currencyChild') {
                this.showSolution = true
            }
        },
        toRoute(val) {
            this.$router.push(val)
        },
        toLogin(){
            let from = this.$route.path
            let newsDetailid=from=='/newsDetails'?this.$route.query.id:''
            this.$router.push({
                path:'/login',
                query:{
                    from,
                    newsDetailId:newsDetailid
                }
            })
        },
        toTop() {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        },
        getData(data) {
            this.$store.state.pages = data.pages
        },
        logout(){
            this.$router.push({
                path: '/login'
            });
            localStorage.setItem('username', '');
            window.location.reload()
        },
        clickDropDown(command){
            switch (command) {
                case 'logout':
                    this.logout();
                    break
                default:
                    break;
            }
        },
        submitApply(){
             let params = {
                ...this.form,
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    userService.advisory(params).then(res => {
                        if (res.code == 200) {
                            this.$message.success('提交成功！')
                            this.applyVisible=false
                        }else{
                            this.$message.warning(res.msg)
                        }
                    })
                } else {
                    return false;
                }
            });
        }
    }
}

</script>

<style lang="scss">

#app {
   
    .screenBlack{
        position: fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:9999999;
        background: #141414a2;
    }
    .solutionWrap{
        width: 80%;
        position: absolute;
        top:0.9rem;left:10%;
        z-index: 88888;
        border-top:0.01rem solid #dedede;
    }
    .dialog {
        width: 6rem;
        height: 3rem;
        position: fixed;
        top: calc(50% - 2.2rem);
        left:calc(50% - 3rem);
        box-shadow: 0 0.05rem 0.15rem 0 rgba(21, 94, 176, 0.31);
        border-radius: 0.06rem;
        z-index: 99999999;
        background-color: #FFFFFF;
        font-size: 0.14rem;
        color: #333333;
        border-top: 0.02rem solid #0061E3;
        font-family: '黑体';
        .title {
            color: #fff;
            font-size: 0.18rem;
            text-align: center;
            letter-spacing: 0.02rem;
            line-height: 0.85rem;
        }
        .flex-center-column{
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
        .dialog-main{
            padding: 0.4rem 0.6rem;
        }
        .formlist{
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.24rem;

        }
        .dialog-main2{
            padding:0.2rem 0.2rem 0.3rem 0.3rem
        }
        .info {
            margin-top: 0.2rem;
            display: flex;
            justify-content: flex-start;
            width: 100%;
            .left {
                width: 0.6rem;
                font-weight: 600;
            }
        }
    }
    .customer {
        position: fixed;
        bottom:1.2rem;
        right: 0.06rem;
        width: 0.44rem;
        z-index: 99;
        font-size: 0.14rem;
        .icon {
            display: inline-block;
            width: 0.4rem;
            height: 0.36rem;
            background-color: #0061E3;
            border-radius: 0.06rem;
            background-image: url("./assets/images/customer.png");
            background-repeat: no-repeat;
            background-position: center center;
            cursor: pointer;
            position: relative;
            top:0.24rem;
        }
        .consultBtn {
            display: inline-block;
            cursor: pointer;
            margin-top: 0.04rem;
            width: 0.4rem;
            height: 0.8rem;
            font-size: 0.14rem;
            background: #0061E3;
            border-radius: 0.06rem;
            color: #fff;
            text-align: center;
            padding-top: 0.14rem;
            span {
                display: inline-block;
                width: 0.12rem;
            }
        }
        img {
            cursor: pointer;
            position: relative;
            top:0.05rem;
            left: 0.05rem;
            width: 0.32rem;
        }
    }
}
.background{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.footer-flex{
    display: flex;
    justify-content: space-between;
}
.item-wrap{
    margin:0.16rem 0;
    display: flex;
    justify-content: flex-start;
    &-icon{
        width: 0.3rem;
        height: 0.3rem;
        position: relative;
        border-radius: 0.04rem;
        margin-right: 0.1rem;
    }
    &-text{
        font-size: 0.12rem;
        color:#efefef;
    }
}
.service-wrap{
  width: 100%;
  height: 2.14rem;
  background: linear-gradient(to right, #DBF5FF, #2782E8);
}
.service{
  width: 12rem;
  line-height:0.32rem;
  height: 2.14rem;
  margin:0 auto;
  text-align: center;
  font-size: 0.24rem;
  font-weight: 600;
  color:#fff;
  background-repeat: no-repeat;
  background-position-y: 0.2rem;
  position:relative;
  background-size: 4rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
//   letter-spacing: 0.04rem;
  top:-0.1rem;
  &-button{
      background:#8bbcf500  !important;
      border-color:#fff  !important;
      position:relative;
      font-size: 0.12rem !important;
      letter-spacing: 0.01rem;
      top:0.2rem;
  }
  &-button:hover{
    border-color:#8bbcf5  !important;
    color:#8bbcf5  !important;
  }
  &-remark{
      position: absolute;
      bottom:0.6rem;
      left:0.2rem;
      font-size: 0.12rem;
      color:#fff;
  }
}
.logo-wrap{
  width: 2.3rem;
  height: 0.6rem;
  margin-left:0.02rem;
  z-index: 999999;
  position: relative;
}
.logo{
  background-repeat: no-repeat;
  top:0.1rem;
  left: .2rem;
  width: 2.5rem;
  height: 0.46rem;
  position: absolute;
  background-size:contain;
}
#app {
    -webkit-text-size-adjust: none !important;
}
header{
    width: 100%;
    height:0.7rem;
    background: #fff;
}
.header{
    width: 80%;
    max-width: 16rem;
    margin:0 auto;
    color:#585858;
    height:0.7rem;
    line-height: 0.7rem;
    font-size: 0.30rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    &-right{
        display: flex;
        &-button{
            width: 1.6rem;
            text-align: right;
            border-left: 0.01rem solid #dedede;
            margin-left:0.24rem;
        }
    }
    .username {
        position:absolute;
        right: 0.5rem;
        color: #2a12b19e;
        font-size: 0.22rem;
        cursor: pointer;

        .el-dropdown {
            width: 1rem;
            text-align: right;
            font-size: 0.2rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
.bannerImg {
    width: 100%;
    height: 4rem;
}
main{
    width:100%;
    margin:0 auto;
    min-height: calc(100% - 3.14rem);
    background: #fff;
    font-size: 0.14rem;
}
// .footer {
//     width: 100%;
//     height: 2.6rem;
    // overflow: hidden;
    // margin-top: 0.5rem;
    // background-color: #1f3a74;
    // background-image: url("./assets/images/footnew.png");

    .footer_container {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .footter-logo{
            width: 1.2rem;
            height: 1.2rem;
            margin-top: 0.3rem;
        }
    }
// }
.num {
    background-color: #171d25;
    text-align: center;
    height: 0.3rem;
    color: #c5c5c5;
    line-height: 0.3rem;
    width: 100%;
    font-size: 0.12rem;
    img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        right: 0.1rem;
    }
}
.footer-bottom-content{
    height:0.4rem;
    width: 80%;
    max-width: 16rem;
}
.container{
    height: 100%;
    width: 100%;
}
#app{
  height: 100%;
//   font-family:'SourceHanSansCN-Medium';
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}
.el-tabs__item{
    font-weight: 500 !important;
    padding:0 0.3rem !important;
    height:0.4rem !important;
    font-size: 0.16rem !important;
    line-height: 0.4rem !important;
    font-family: '黑体';
}
.el-tabs__header{
    margin:0 0 0.15rem !important;
}
.el-tabs__nav-wrap:after{
  background-color:#fff !important;
}
.el-tabs__item.is-active{
    font-weight: 600!important;
}
.is-active{
    font-weight: 600!important;
    color:#333333!important
}
.el-tabs__item:hover{
        font-weight: 600!important;
    color:#333333!important
}
.el-tabs__item .tabs_title{
    color: #303133;    
    height: 0.4rem !important;
    font-size: 0.16rem !important;
    line-height: 0.4rem !important;
}
.dialog .el-textarea__inner{
    height: 0.64rem;
}
.dialog .el-button--primary{
    width: 3.2rem;
    height: 0.32rem;
    background: #0061E3;
    border-radius: 0.04rem;
    text-align: center;
    font-size: 0.14rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FAFBFA;
    cursor: pointer;
    margin-left: 2%;
}
.dialog .el-textarea__inner{
    background: #FAFBFA;
}
.dialog .el-input__inner{
    background: #FAFBFA;
}
.dialog .el-form {  
    font-family: Source Han Sans CN;
}
</style>
